import React from "react";
// Customizable Area Start
import { styled } from "@mui/styles";
import { BlockComponent } from "../../framework/src/BlockComponent";
import {
    Box,
    AppBar,
    Toolbar,
    Button,

} from "@mui/material";
import { analyticsIcon, bciImage, createClientIcon, previousQoutesIcon, support } from "./assets";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

// Customizable Area End



interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class NavigationMenuBar extends BlockComponent<Props, S, SS> {

    static instance: NavigationMenuBar;

    constructor(props: Props) {
        super(props);
        NavigationMenuBar.instance = this;
    }

    render() {
        const { navigation } = this.props;

        return (
            <NavOuterBox>
                <AppBar position="static" style={{ background: "#171717", padding: "12px 0" }}>
                    <Toolbar style={{
                        display: "flex", justifyContent: "space-between", width: "90%", flexWrap: "wrap"
                    }}>
                        <Box style={{ marginLeft: "3rem" }}>
                            <img src={bciImage} alt="img" style={{
                                width: "107px"
                            }} />
                        </Box>

                        <Box style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                            <Button color="inherit"
                                variant="contained"
                                sx={{
                                    ...webStyle.createClientBtn,
                                    '&:hover': {
                                        backgroundColor: '#C7161D'
                                    },
                                }} >
                                <span>
                                    <img src={createClientIcon} alt="" />
                                </span>
                                Create Client </Button>
                            <Button color="inherit"
                                sx={webStyle.previousQoutesbtn} >
                                <span>
                                    <img src={previousQoutesIcon} alt="" />
                                </span>Previous Quotes </Button>
                            <Button color="inherit"
                                sx={webStyle.analyticsbtn}>
                                <span>
                                    <img src={analyticsIcon} alt="" />
                                </span>
                                Analytics </Button>
                        </Box>

                        <Box style={{ display: "flex", gap: "0.5rem", color: "white", alignItems: "center" }}>
                            <Box
                                style={{
                                    border: '1px solid #57534E', borderRadius: "8px", padding: "6px 16px",
                                }}>
                                <SettingsOutlinedIcon />
                            </Box>
                            <span
                                style={{
                                    border: '1px solid #57534E', borderRadius: "8px", padding: "8px 16px",
                                }}>
                                <img src={support} alt="" />

                            </span>
                        </Box>

                        <Box>
                            <Button sx={webStyle.logoutButton}>
                                <LogoutOutlinedIcon />
                                Log Out
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
            </NavOuterBox>

        );
    }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
    passwordTypo: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        color: "#78716C",
        display: "flex",
        justifyContent: "space-between",
        whiteSpace: "noWrap"
    },
    passwordTypo2: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#C7161D",
        display: "flex",
        justifyContent: "space-between",
        whiteSpace: "noWrap"
    },
    logoutButton: {
        border: "1px solid #57534E",
        color: "#ffffff",
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "flex-start",
        gap: "16px",
        padding: "8px 16px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        borderRadius: "8px",
    },
    createClientBtn: {
        background: "#C7161D",
        display: "flex",
        gap: "15px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        borderRadius: "8px",
        textTransform: "capitalize",
    },
    previousQoutesbtn: {
        display: "flex", gap: "15px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        borderRadius: "8px",
        textTransform: "capitalize",
    },
    analyticsbtn: {
        display: "flex", gap: "15px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        borderRadius: "8px",
        textTransform: "capitalize",
    },
    CheckBoxStyle: {
        width: "16px",
        height: "16px",
        borderRadius: "4px",
        border: "1px solid #78716C",
    },
    checkBoxLabel: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "13.79px",
        color: "#171717"
    },
    checkBoxLabel2: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#78716C"
    },
    hrClasss: {
        width: "100%",
        color: "#D6D3D1",
        margin: "2rem 0rem"

    }

};

const NavOuterBox = styled(Box)({
    flexGrow: 1

});

// Customizable Area End
export default NavigationMenuBar;