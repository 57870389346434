Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';

exports.getTermsCondsApiEndPoint = 'terms_and_conditions/latest_record';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';

exports.createTermsConds = 'Create New'
exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms & Conditions';
exports.termsCondsUserList = 'Users That Accepted List';
exports.termsAndConditions="Terms and Conditions"
exports.back="Back"
exports.dateFormat = "MM-DD-YYYY";
exports.termsConditionsString = `
  <div>
    <p>Welcome to Our Website! These terms and conditions outline the rules and regulations for the use of Our Website.</p>
    
    <h3>1. Acceptance of Terms</h3>
    <p>By accessing this website, you agree to be bound by these terms and conditions.</p>
    
    <h3>2. License to Use</h3>
    <p>You are granted a limited license to access and use the website for personal purposes, subject to the restrictions set in these terms.</p>
    
    <h3>3. User Responsibilities</h3>
    <p>As a user, you agree to not misuse the website or violate any applicable laws.</p>
    
    <h3>4. Disclaimer</h3>
    <p>All the information on this website is provided "as is" without any warranties, and we are not liable for any damages that may arise from its use.</p>
    
    <h3>5. Changes to Terms</h3>
    <p>We reserve the right to modify these terms at any time, so please review them regularly.</p>
    
    <p>Last updated: 1st January 2024</p>
  </div>
`;
  // Customizable Area End