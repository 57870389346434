// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CircleIcon from '@mui/icons-material/Circle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { styled } from "@mui/material"

const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    validationsCreate: boolean[];
    currentPasswordShow: boolean;
    newPasswordShow: boolean;
    confirmNewPasswordShow: boolean;
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    passwordNotMatch: boolean;
    successModal: boolean;
    validationCircle: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ChangePasswordSettingsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            validationsCreate: [false, false, false, false],
            currentPasswordShow: false,
            newPasswordShow: false,
            confirmNewPasswordShow: false,
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            passwordNotMatch: false,
            successModal: false,
            validationCircle: true
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    handleCurrentPasswordVis = () => {
        this.setState({
            currentPasswordShow: !this.state.currentPasswordShow
        })
    };

    handleNewPasswordVis = () => {
        this.setState({
            newPasswordShow: !this.state.newPasswordShow
        })
    };

    handleConfirmNewPasswordVis = () => {
        this.setState({
            confirmNewPasswordShow: !this.state.confirmNewPasswordShow
        })
    };

    handleCurrentPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        if(name === "currentPassword") {
            this.setState({
                currentPassword: value,
            })
        }
    };

    validatePassword = (newPassword: string): boolean[] => {
        const lengthValid = newPassword.length >= 8;
        const uppercaseValid = /[A-Z]/.test(newPassword);
        const lowercaseValid = /[a-z]/.test(newPassword);
        const numberValid = /\d/.test(newPassword);
        const specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

        return [
            lengthValid,
            uppercaseValid,
            lowercaseValid,
            numberValid,
            specialCharValid,
        ];
    };

    handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        const validationsCreate = this.validatePassword(newPassword);
        this.setState({ newPassword, validationsCreate, passwordNotMatch: false, validationCircle: false });
    };

    handleConfirmNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        if(name === "confirmNewPassword") {
            this.setState({
                confirmNewPassword: value,
                passwordNotMatch: false
            })
        }
    };

    changePassword = () => {
        if(this.state.newPassword != this.state.confirmNewPassword || this.state.newPassword === "" || this.state.confirmNewPassword === "") {
            this.setState({
                passwordNotMatch: true
            })
        }
        else {
            this.setState({
                successModal: true
            })
        }
    };

    goToLogin = () => {
        this.setState({
            successModal: false
        });
        const goToLogin: Message = new Message(getName(MessageEnum.NavigationMessage));
        goToLogin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        goToLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(goToLogin);
    };

    validationCircleCondition = () => {
        return (
            this.state.validationCircle ? <CircleIcon fontSize="small" style={{ color: "#78716C" }} /> : <CancelRoundedIcon style={{ color: "red" }} />
        )
    };

    validationsCreate = () => {
        return (
            <CustomUl>
                <li style={{ color: this.state.validationsCreate[0] ? "#171717" : "#78716C" }} className="listNames">
                    {this.state.validationsCreate[0] ? <CheckCircleRoundedIcon style={{ color:"#10B981" }}/> : this.validationCircleCondition() } {configJSON.capitalLetterVal}
                </li>
                <li style={{ color: this.state.validationsCreate[1] ? "#171717" : "#78716C" }} className="listNames">
                    {this.state.validationsCreate[1] ? <CheckCircleRoundedIcon style={{ color:"#10B981" }}/> : this.validationCircleCondition() } {configJSON.lowercaseLetterVal}
                </li>
                <li style={{ color: this.state.validationsCreate[2] ? "#171717" : "#78716C" }} className="listNames">
                    {this.state.validationsCreate[2] ? <CheckCircleRoundedIcon style={{ color:"#10B981" }}/> : this.validationCircleCondition() } {configJSON.numberVal}
                </li>
                <li style={{ color: this.state.validationsCreate[3] ? "#171717" : "#78716C" }} className="listNames">
                    {this.state.validationsCreate[3] ? <CheckCircleRoundedIcon style={{ color:"#10B981" }}/> : this.validationCircleCondition() } {configJSON.minCharVal}
                </li>
            </CustomUl>
        )
    };
    // Customizable Area End
}

// Customizable Area Start
const CustomUl = styled("ul")({
    listStyleType: 'none',
    padding: 0,
    marginBottom: "32px",
    width: "50%",
    "@media(max-width:1200px)": {
        width: "40%"
    },
    "@media(max-width:550px)": {
        width: "50%"
    },
    "@media(max-width:350px)": {
        width: "65%"
    },
    "& .listNames": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 400,
        // lineHeight: "22px",
        color: "#171717",
    }
})
// Customizable Area End