// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

interface PrivacyPolicy{
    description: string
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabValue: number;
    privacyPolicyData: PrivacyPolicy
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class TnCandPrivacyPolicyController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    privacyPolicyCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];
    
        this.state = {
            // Customizable Area Start
            tabValue: 0,
            privacyPolicyData: {
                description: ""
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      }

      // Customizable Area Start
      async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId != null) {
                if(apiRequestCallId === this.privacyPolicyCallId){
                    this.setState({
                        privacyPolicyData: responseJson
                    })
                }
            }
        }
        // Customizable Area End
    };

    async componentDidMount(){
        this.getPrivacyPolicy()
    };

      handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            tabValue: newValue
        })
      };

      a11yProps(index: number) {
        return {
            id: `wrapped-tab-${index}`,
            'aria-controls': `wrapped-tabpanel-${index}`,
        };
    };

    getPrivacyPolicy = async () => {
        const header = {
            "Content-Type": "application/json",
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.privacyPolicyCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_terms_and_conditions/api/v1/privacy_policies"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return this.privacyPolicyCallId;
    };
      // Customizable Area End
}

// Customizable Area Start
// Customizable Area End