import React from "react";

// Customizable Area Start
import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  IconButton,
  Checkbox
} from "@mui/material";
import {
  ArrowBack,
  CheckCircleOutlineRounded,
  HighlightOff,
  VisibilityOffOutlined,
  VisibilityOutlined,
  CheckCircle, Cancel,
  Image,
  Close
} from '@mui/icons-material';
import {
  belowBannerImage,
  aboveBannerImage,
  mobileBelowImage,
  logo,
  upload,
  activeUpload,
  checkedBox,
  unCheckedBox
} from "./assets"
const theme = createTheme({
  typography: {
    fontFamily: "Quicksand"
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#E7E5E4',
          borderColor: '#E7E5E4',
          borderRadius: "8px",
          height: "56px",
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E7E5E4',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E7E5E4',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E7E5E4',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
          },
        },
      },
    },
  },
});
  const instructionConditionJSX=(firstCondition:boolean,secondCondition:boolean)=>{
    if (firstCondition) {
      return <Box sx={styles.instructionsIconBox}>
        <Box sx={styles.smallCircle} />
      </Box>
    } 
     if (secondCondition) {
      return <Box sx={styles.instructionsIconBox}>
        <CheckCircle sx={styles.circleIcon} />
      </Box>
    } else {
      return <Box sx={styles.instructionsIconBox}>
        <Cancel sx={styles.closeIcon} />
      </Box>
    }
  }
  const handleInstructionsJSX = (password: string, instructions: { regex: { test: (value: string) => boolean }, text: string }[]) => {
    return <Box sx={styles.insideInnerMainBox}>
      <Box sx={styles.insideInnerBox}>
        {instructions.map((instruction: { regex: { test: (value: string) => boolean }, text: string }) => (
          <Box sx={styles.instructionBox} key={instruction.text}>
            {instructionConditionJSX(password.length === 0, instruction.regex.test(password)) }
            <Typography sx={styles.instructionText}>{instruction.text}</Typography>
          </Box>
        ))}
        <Box sx={styles.instructionBox}>
           {instructionConditionJSX(password.length === 0 , password.length >= 8 )}
          <Typography sx={styles.instructionText}>{configJSON.minEightLength}</Typography>
        </Box>
      </Box>
    </Box>
  }
// Customizable Area End

import EmailAccountRegistrationController, {
    Props,
    configJSON
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
      const {
         displayAlert, 
         inputs,
         showPassword,
         profilePictureFile,
         isCheckedTermsPrivacy,
         stepValue,
         otp
       } = this.state
      const mainBodyJSX = () => {
        switch (stepValue) {
          case 1:
            return (<>
              <Typography sx={styles.blockHeading} data-testid="heading">
                {configJSON.signUp}
              </Typography>
              <Box sx={styles.contentBox}>
                {inputs.map((input, index) => (
                  <React.Fragment key={input.key}>
                    <Box sx={styles.inputBox}>
                      <Box sx={styles.inputLabelBox}>
                        <Typography sx={styles.inputName}>{input.name}</Typography>
                        <Typography data-testid={`${input.key}-error-text`} sx={styles.errorMessage}>{input.errorMessage}</Typography>
                      </Box>
                      <TextField
                        data-testid={input.key}
                        autoComplete="off"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOnChange(event.target.value, input.key, index)}
                        onBlur={() => this.handleOnBlur(input.key, index)}
                        onKeyDown={this.handleOnKeyDown}
                        InputProps={{ endAdornment: this.conditionIcon(input.key === "pwd", <IconButton onClick={this.handlePasswordEye}>{this.conditionIcon(!showPassword, <VisibilityOffOutlined />, <VisibilityOutlined />)}</IconButton>, <></>) }}
                        fullWidth
                        type={this.conditionFunc(input.key === "pwd", this.conditionFunc(showPassword, "text", "password"), input.type)}
                        name={input.name}
                        value={input.value}
                        placeholder={input.placeHolder}
                        error={Boolean(input.errorMessage)}
                      />
                    </Box>
                    {this.conditionIcon(input.key === "pwd", handleInstructionsJSX(inputs[3].value, this.passwordInstructions), <></>)}
                  </React.Fragment>
                ))}
                <Box sx={styles.inputBox}>
                  <Box sx={styles.inputLabelBox}>
                    <Typography sx={styles.inputName}>{configJSON.profilePictureOptional}</Typography>
                  </Box>
                  <Box sx={styles.uploadBox}>
                    <TextField
                      onChange={this.handleProfilePicture}
                      id="uploadImage"
                      sx={styles.fileInput}
                      fullWidth
                      type="file"
                      data-testid="profile-picture"
                    />
                    <IconButton component={"label"} htmlFor="uploadImage">
                      <Box component={"img"} src={this.conditionFunc(Boolean(profilePictureFile), activeUpload, upload)} sx={styles.uploadImage} />
                    </IconButton>
                    <Typography sx={styles.uploadText}>{configJSON.uploadImage}</Typography>
                  </Box>
                </Box>
                {this.conditionIcon(Boolean(profilePictureFile), <Box sx={styles.imageNameMainBox}>
                  <Box sx={styles.imageBox}>
                    <Image sx={styles.imageIcon} />
                    <Typography sx={styles.imageName} data-testid="profile-picture-name">{profilePictureFile?.name}</Typography>
                  </Box>
                  <IconButton data-testid="remove-profile-picture" onClick={this.handleRemoveUploadedImage}>
                    <Close sx={styles.close} />
                  </IconButton>
                </Box>, <></>)}
                <Box sx={styles.termsAndPrivacyMainBox}>
                  <Checkbox
                    data-testid="checkBox"
                    sx={styles.checkBox}
                    onChange={this.handleClickTermsAndPrivacy}
                    value={isCheckedTermsPrivacy}
                    checkedIcon={<Box component={"img"} src={checkedBox} />}
                    icon={<Box component={"img"} src={unCheckedBox} />}
                  />
                  <Typography sx={styles.termsAndPrivacyText}>
                    {configJSON.iAgreeWithText}
                    <Typography data-testid="terms-and-conditions-text" component={"a"} onClick={() => this.handleNavigate("TermsConditions")} sx={styles.navigateText}>{configJSON.tryWith} {configJSON.termsAndConditionsText}</Typography>
                    {configJSON.andText}
                    <Typography data-testid="privacy-and-policy-text" component={"a"} sx={styles.navigateText}>{configJSON.tryWith} {configJSON.privacyPolicy}</Typography>
                  </Typography>
                </Box>
              </Box>
            </>)
          case 2:
            return (
              <>
                <Typography sx={styles.blockHeading} data-testid="heading">
                  {configJSON.pleaseCheckYourEmailText}
                </Typography>
                <Box sx={styles.contentBox}>
                  <Box sx={styles.emailContentBox}>
                    <Typography sx={styles.welcomeText}>
                      {String(configJSON.welComeText).slice(0, 10)}
                      <Typography sx={styles.welcomeBoldText} component={"span"}>{String(configJSON.welComeText).slice(10, 47)}</Typography>
                      {String(configJSON.welComeText).slice(47)}
                    </Typography>

                    <Typography sx={styles.welcomeText}>
                      {String(configJSON.checkYourText).slice(0, 70)}
                      <Typography sx={styles.welcomeBoldText} component={"span"}>{String(configJSON.checkYourText).slice(70)}</Typography>
                    </Typography>
                  </Box>
                </Box>
              </>
            )
          case 3:
            return (
              <>
              <Typography sx={styles.blockHeading} data-testid="heading">
                  {configJSON.enterCode}
                </Typography>
                <Typography sx={styles.headingBelowText}>{configJSON.enterCodeDescription}</Typography>
                <Box sx={styles.contentBox}>
                <Box sx={styles.insideInnerMainBox}>
                  <Box sx={styles.otpFieldBox}>
                    {otp.map((value, index: number) => (
                        <TextField
                          data-testid="otp-field"
                          sx={styles.otpField}
                          placeholder={"-"}
                          key={index}
                          type="text"
                          inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                          value={value}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOtpOnChange(event, index)}
                          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => this.handleOtpKeyDown(event, index)}
                          inputRef={this.otpInputs[index]}
                        />
                      ))
                    }
                  </Box>
                </Box>
                <Box sx={styles.resendTextBox(true)}>
                    <Typography 
                    data-testid="resend-text" 
                    component={"a"} 
                    onClick={this.handleResentTheCode} 
                    sx={styles.resendText}>{configJSON.resendCode}</Typography>
                </Box>
                </Box>
              </>
            )
            default:{
              return (
                <>
                  <Typography sx={styles.blockHeading} data-testid="heading">
                    {configJSON.weWillNotifyText}
                  </Typography>
                  <Box sx={styles.contentBox}>
                    <Box sx={styles.emailContentBox}>
                      <Typography sx={styles.welcomeText}>
                        {configJSON.thankYouText}
                      </Typography>
                      <Typography sx={styles.welcomeText}>
                        {configJSON.thisMayTakeText}
                       </Typography>
                    </Box>
                  </Box>
                </>
              )
            }
        }
      }
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={styles.mainBox}>
            <Box sx={styles.bannerMainBox}>
              <IconButton data-testid="go-back-navigate" sx={styles.iconButton} onClick={() => this.handleNavigate("NavigationMenu")}>
                <ArrowBack sx={styles.icon} />
              </IconButton>
              <Box component={"img"} src={mobileBelowImage} alt="banner image" sx={styles.mobileBannerImage} />
              <Box component={"img"} src={logo} sx={styles.logoImage} />
              <Box component={"img"} src={belowBannerImage} sx={styles.belowBannerImage} />
              <Box component={"img"} src={aboveBannerImage} sx={styles.aboveBannerImage} />
            </Box>
            <Box sx={styles.contentMainBox}>
              <Box sx={styles.contentInnerBox(stepValue)} data-testid="profile-form" component={"form"} onSubmit={this.handleOnSubmit}>
              <Typography data-testid="alert-message" sx={styles.errorText(Boolean(displayAlert.message),displayAlert.isSuccess)}>
                 <IconButton data-testid="remove-error-button" onClick={this.handleRemoveError}>
                   {this.conditionIcon(displayAlert.isSuccess , <CheckCircleOutlineRounded /> , < HighlightOff />)}
                 </IconButton>
                 {displayAlert.message}
               </Typography>
                {mainBodyJSX()}
                <Button type="submit" sx={styles.button(this.handleCheckButton())}>{this.buttonText[stepValue-1]}</Button>
                {this.conditionIcon(stepValue === 2, <Box sx={styles.emailResendBox}>
                  <Typography sx={styles.welcomeText}>{configJSON.didYouText}</Typography>
                  <Box sx={styles.resendTextBox(false)}>
                    <Typography data-testid="resend-text" component={"a"} onClick={this.handleResentTheCode} sx={styles.resendText}>{configJSON.resendCode}</Typography>
                  </Box>
                </Box>,
                <></>)}
              </Box>
            </Box>
          </Box>
        </ThemeProvider>);
        // Customizable Area End
    }

}
// Customizable Area Start
const styles = {
  headingBelowText: {
    color: "#78716C",
    fontWeight: 400,
    fontSize: "14px",
    mt:"-7%",
    mb:"5%"
  },
  otpFieldBox: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    my: "2%",
    height: "70px",
    [theme.breakpoints.up("md")]: {
      width: 340
    },
    [theme.breakpoints.up("sm")]: {
      width: 320
    },
    [theme.breakpoints.up("xs")]: {
      width: 300
    }
  },
  otpField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#E7E5E4",
      color: "#171717",
       fontSize: 16,
      fontWeight: 500,
       borderRadius: "5px",
      height: "56px",
      width: "56px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  welcomeText: { 
    fontSize: "14px", 
    color: "#78716C", 
    fontWeight: 400 
  },
  welcomeBoldText: { 
    fontSize: "14px", 
    color: "#78716C", 
    fontWeight: 700 
  },
  emailContentBox: { 
    display: "flex", 
    flexDirection: "column", 
    gap: 2.5, 
    mb: "10%" 
  },
  emailResendBox: { 
    display: "flex", 
    flexDirection: "column", 
    gap: 3, 
    my: "8%" 
  },
  resendTextBox:(isCurrent:boolean)=>( { 
    display: "flex", 
    alignItems: "center",
    mb:isCurrent?"5%":"0%"
  }),  
  imageNameMainBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  imageBox: {
    display: "flex",
    alignItems: "center",
    gap: "2%",
    width: "100%"
  },
  imageIcon: {
    color: "#C7161D",
    fontSize: "22px"
  },
  imageName: {
    fontSize: "10px",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  },
  close: {
    color: "#C7161D"
  },
  termsAndPrivacyMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    my: "6%",
    gap: "2%",
    width: "300px",
    mx: "auto"
  },
  checkBox: {
    width: "16px",
    height: "16px"
  },
  termsAndPrivacyText: {
    fontSize: "10px",
    fontWeight: 400,
    color: "#78716C"
  },
  instructionsIconBox: {
    width: "18px",
    height: "18px",
    display: "grid",
    placeItems: "center"
  },
  circleIcon: {
    color: "#10B981",
    fontSize: "18px"
  },
  closeIcon: {
    color: "#DC2626",
    fontSize: "18px"
  },
  smallCircle: {
    height: "8px",
    width: "8px",
    bgcolor: "#A8A29E",
    borderRadius: 30
  },
  instructionBox: {
    height: "22px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
    mb: "2%"
  },
  instructionText: {
    color: "#171717",
    fontWeight: 400,
    fontSize: "14px"
  },
  insideInnerMainBox: {
    display: "flex",
    justifyContent: "center",
    my: "1%"
  },

  insideInnerBox: {
    [theme.breakpoints.up("xs")]: {
      width: "310px"
    }
  },
  button: (isExist: boolean) => ({
    "&.MuiButton-root": {
      bgcolor: isExist ? "#C7161D" : "#A8A29E",
      color: !isExist ? "#D6D3D1" : "#FFFFFF",
      borderRadius: "8px",
      height: "55px",
      width: "100%",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "18px",
    },
  }),
  contentBox: {
    width: "100%",
  },
  inputBox: {
    width: "100%",
    mb: "4%"
  },
  inputName: {
    fontWeight: 700,
    color: "#78716C",
  },
  inputLabelBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mb: 0.5,
  },
  errorMessage: {
    fontWeight: 700,
    fontSize: "10px",
    color: "#C7161D",
  },
  uploadBox: {
    border: "1px dashed #B4B4B4",
    height: "52px",
    p: "8px",
    borderRadius: "8px",
    gap: "2px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fileInput: {
    display: "none",
  },
  uploadImage: {
    width: "36px",
    height: "36px",
  },
  uploadText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#78716C",
  },
  mainBox: {
    display: "flex",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
  },
  bannerMainBox: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "50%",
    },
    [theme.breakpoints.up("xs")]: {
      height: "160px",
      width: "100%",
    },
  },
  belowBannerImage: {
    position: "absolute",
    zIndex: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [theme.breakpoints.up("md")]: {
      borderRadius: "25px",
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      borderRadius: "25px 25px 0px 0px",
      display: "none",
    },
  },
  aboveBannerImage: {
    position: "absolute",
    zIndex: 2,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [theme.breakpoints.up("md")]: {
      borderRadius: "25px",
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      borderRadius: "25px 25px 0px 0px",
      display: "none",
    },
  },
  contentMainBox: {
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "50%",
      alignItems: "center",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      width: "100%",
      alignItems: "start",
    },
  },
  contentInnerBox:(stepValue:number)=>({
    width: "343px",
    [theme.breakpoints.up("md")]: {
      pt:stepValue===1?"35%":"0%",
      pb:stepValue===1? "3%":"0%",
    },
  }),
  errorText: (isError: boolean, isSuccess: boolean) => ({
    visibility: isError ? "visible" : "hidden",
    fontWeight: 700,
    fontSize: "12px",
    textAlign: "start",
    color: isSuccess ? "#059669" : "#DC2626",
    bgcolor: "#F5F5F4",
    width: "100%",
     p: "8px 8px",
    my: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "md")]: { mt: "5%", },
    "& .MuiSvgIcon-root": { color: isSuccess ? "#059669" : "DC2626" }
  }),
  blockHeading: {
    fontWeight: 700,
    fontSize: 32,
    color: "#171717",
    mb: "8%",
  },
  navigateTextBox: () => ({
    display: "flex",
    justifyContent: "end",
    mt: "5%",
  }),
  resendText: {
    fontWeight: 700,
    borderBottom: "1px solid #171717",
    pb: 0.5,
    fontSize: "12px",
    textAlign:"center",
    color:"#171717",
    mx:"auto",
  },
  navigateText: {
    fontWeight: 700,
    borderBottom: "1px solid #78716C",
    pb: 0.5,
    fontSize: "10px"
  },
  createAccountBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    mt: "3%",
  },
  doNotHaveText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#171717",
  },
  createOneText: {
    fontWeight: 700,
    fontSize: "12px",
    color: "#C7161D",
  },
  iconButton: {
    position: "absolute",
    color: "white",
    left: 15,
    top: 10,
    zIndex: 3,
    display: "none",
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
  icon: {
    fontSize: 30,
  },
  mobileBannerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      objectPosition: "top",
      display: "block",
      borderRadius: "25px 25px 0px 0px",
    },
  },
  logoImage: {
    height: "40px",
    width: "90px",
    position: "absolute",
    zIndex: 2,
    left: "50%",
    transform: "translate(-50%, 50%)",
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "none",
    },
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
};
// Customizable Area End

