import React from "react";
// Customizable Area Start
import ChangePasswordSettingsController, {
    Props
} from "./ChangePasswordSettingsController.web";
import {
    Grid,
    Typography,
    styled,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    Modal
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { APP_CONSTANT } from "../../../components/src/AppConstant";

const configJSON = require("./config");
// Customizable Area End

export default class ChangePasswordSettings extends ChangePasswordSettingsController{
    constructor(props: Props){
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return(
            // Customizable Area Start
            <MainGrid container>
                {this.state.passwordNotMatch && <Grid item className="errorGrid">
                    <CancelOutlinedIcon style={{ color: "#DC2626" }} /> {APP_CONSTANT.errorMessage}
                </Grid>}

                <Grid item>
                    <Typography className="changePasswordText">
                        {configJSON.changePassword}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography className="fieldLabel">{configJSON.currentPassword}</Typography>
                    <TextField 
                        name="currentPassword"
                        className="passwordFields"
                        data-test-id="createPassword"
                        type={
                            this.state.currentPasswordShow ? "text" : "password"
                        }
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle passwordCreate visibility"
                                        onClick={this.handleCurrentPasswordVis}
                                        data-test-id="passwordCreate-visibility"
                                        edge="end"
                                    >
                                        {this.state.currentPasswordShow ? (
                                            <Visibility style={{ color: "#78716C" }} />
                                        ) : (
                                            <VisibilityOff style={{ color: "#78716C" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Your Password"
                        value={this.state.currentPassword}
                        onChange={this.handleCurrentPassword}
                    />

                    <Typography className="fieldLabel">{configJSON.newPassword}</Typography>
                    <TextField 
                        name="NewPassword"
                        data-test-id="newPassword"
                        className="passwordFields"
                        value={this.state.newPassword}
                        placeholder="Your Password"
                        onChange={this.handleNewPasswordChange}
                        type={
                            this.state.newPasswordShow ? "text" : "password"
                        }
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle passwordCreate visibility"
                                        onClick={this.handleNewPasswordVis}
                                        data-test-id="passwordNew-visibility"
                                        edge="end"
                                    >
                                        {this.state.newPasswordShow ? (
                                            <Visibility style={{ color: "#78716C" }} />
                                        ) : (
                                            <VisibilityOff style={{ color: "#78716C" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Typography className="fieldLabel">{configJSON.confirmNewPassword}</Typography>
                    <TextField 
                        name="confirmNewPassword"
                        className="passwordFields"
                        data-test-id="confirmNewPassword"
                        type={
                            this.state.confirmNewPasswordShow ? "text" : "password"
                        }
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle passwordCreate visibility"
                                        onClick={this.handleConfirmNewPasswordVis}
                                        data-test-id="passwordConfirmNew-visibility"
                                        edge="end"
                                    >
                                        {this.state.confirmNewPasswordShow ? (
                                            <Visibility style={{ color: "#78716C" }} />
                                        ) : (
                                            <VisibilityOff style={{ color: "#78716C" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={this.state.confirmNewPassword}
                        placeholder="Your Password"
                        onChange={this.handleConfirmNewPassword}
                    />
                </Grid>

                <Grid className="validationsGrid">
                    {this.validationsCreate()}
                </Grid>

                <Grid item>
                        <Button data-test-id="changePasswordBtn" onClick={this.changePassword} className="changePasswordBtn">{configJSON.changePasswordBtn}</Button>
                </Grid>
                
                <Grid item className="modal">
                    <CustomModal
                        open={this.state.successModal}
                        onClose={this.goToLogin}
                        className="successModal"
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        data-test-id="successModal"
                    >
                        <Grid container className="modalGrid">
                            <Typography className="successHeading">{configJSON.successHeading}</Typography>
                            <Typography className="successText">{configJSON.successText}</Typography>
                            <Button className="goToLoginBtn">{configJSON.goToLoginBtn}</Button>
                        </Grid>
                    </CustomModal>
                </Grid>
            </MainGrid>
            // Customizable Area ENd
        )
    }
}
// Customizable Area Start
const MainGrid = styled(Grid)({
    width: "77%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    "@media(max-width:1200px)": {
        width: "70%"
    },
    "@media(max-width:700px)": {
        width: "90%"
    },
    "& .changePasswordText": {
        fontFamily: "Quicksand",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "40px",
        color: "#171717",
        marginBottom: "32px"
    },
    "& .fieldLabel": {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#78716C",
    },
    "& .passwordFields": {
        backgroundColor: "#E7E5E4",
        borderRadius: "8px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#78716C",
        width: "100%",
        marginBottom: "32px",
        border: "0px"
    },
    "& .changePasswordBtn": {
        width: "100%",
        backgroundColor: "#C7161D",
        borderRadius: "8px",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        color: "#FFFFFF",
        marginBottom: "32px",
        textTransform: "capitalize",
        padding: "16px 24px 16px 24px",
        "&:hover": {
            backgroundColor: "#C7161D"
        }
    },
    "& .validationsGrid": {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    "& .errorGrid": {
        display: "flex",
        gap: "11.33px",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px 16px 8px 16px",
        borderRadius: "4px",
        backgroundColor: "#F5F5F4",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
    },
});

const CustomModal = styled(Modal)({
    padding: "48px",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    width: "25%",
    height: "50%",
    margin: "auto",
    zIndex: 1300,
    "@media(max-width:1200px)": {
        width: "35%"
    },
    "@media(max-width:900px)": {
        width: "50%"
    },
    "@media(max-width:700px)": {
        width: "75%"
    },
    "& .MuiBackdrop-root": {
        zIndex: 1,
        backgroundColor: "rgba(0, 0, 0, 0.5)"
    },
    "& .modalGrid": {
        backgroundColor: "#FFFFFF",
        padding: "48px",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        zIndex: 1400,
    },
    "& .successHeading": {
        color: "#171717",
        fontFamily: "Quicksand",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "40px",
    },
    "& .successText": {
        color: "#78716C",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
    },
    "& .goToLoginBtn": {
        width: "100%",
        padding: "16px 24px 16px 24px",
        textTransform: "capitalize",
        borderRadius: "8px",
        backgroundColor: "#C7161D",
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "Quicksand",
        color: "#FFFFFF",
        lineHeight: "22.5px",
        "&:hover": {
            backgroundColor: "#C7161D"
        }
    }
})
// Customizable Area End
