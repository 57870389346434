import React from "react";
// Customizable Area Start
import TnCandPrivacyPolicyController, {
    Props
} from "./TnCandPrivacyPolicyController.web";
import {
    Grid,
    styled,
    Tabs,
    Tab,
    Typography,
    Button,
    Box
} from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const images = require("./assets");

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        // style={webStyle.tableGrid}
        >
            {value === index && (
                <Grid >
                    <Typography>{children}</Typography>
                </Grid>
            )}
        </div>
    );
}
// Customizable Area End

export default class TnCandPrivacyPolicy extends TnCandPrivacyPolicyController {
    constructor(props: Props) {
        super(props)
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainGrid container>
                <Grid container className="pageHeader">
                    <img src={images.bciBanner} className="banner" />
                    <img src={images.bciLogo} className="logo" />
                </Grid>

                <Grid container className="mainContentGrid">
                    <Tabs
                        value={this.state.tabValue}
                        className="mainTabs"
                        onChange={(event, index) => this.handleTabChange(event, index)}
                        variant="fullWidth"
                        data-test-id="tabChange"
                        aria-label="icon label tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: '#C7161D' } }}
                        {...this.a11yProps(0)}
                    >
                        <Tab className="tabLabel" label="Terms and Conditions" {...this.a11yProps(0)} />
                        <Tab className="tabLabel" label="Privacy Policy" {...this.a11yProps(1)} />
                    </Tabs>
                </Grid>

                <TabPanel value={this.state.tabValue} index={0}>
                    <Typography className="privacyPolicyHeading">Terms and Conditions</Typography>
                </TabPanel>

                <Grid className="privacyPolicyGrid">
                    {this.state.tabValue === 1 &&
                        <Grid style={{ width: "40%" }}>
                            <Typography className="privacyPolicyHeading">Privacy Policy</Typography>
                            <Box className="privacyBox">
                                <Typography className="tnCText">{this.state.privacyPolicyData.description}</Typography>
                            </Box>
                        </Grid>
                    }

                    <Button className="backButton">
                        <KeyboardBackspaceIcon style={{ color: "#C7161D" }} /> Back
                    </Button>
                </Grid>
            </MainGrid>
            // Customizable Area End
        )
    }
};
// Customizable Area Start
const MainGrid = styled(Grid)({
    "& .pageHeader": {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "48px",
    },
    "& .banner": {
        width: "100%"
    },
    "& .logo": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    "& .mainContentGrid": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    "& .mainTabs": {
        width: "20%",
        "& .MuiTab-root.Mui-selected": {
            color: "#171717 !important",
            fontWeight: 700,
        },
        marginBottom: "32px"
    },
    "& .tabLabel": {
        fontFamily: "Quicksand",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16.8px",
        color: "#171717",
        textTransform: "none",
    },
    "& .tnCText": {
        fontFamily: "Quicksand",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
        color: "#171717",
    },
    "& .privacyPolicyGrid": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
        alignItems: "center"
    },
    "& .privacyPolicyHeading": {
        fontFamily: "Quicksand",
        fontWeight: 700,
        fontSize: "32px",
        lineHeight: "40px",
        color: "#171717",
        marginBottom: "32px"
    },
    "& .buttonGrid": {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    "& .backButton": {
        display: "flex",
        gap: "10px",
        fontFamily: "Cerebri Sans",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "20.32px",
        color: "#C7161D",
        textTransform: "none"
    },
    "& .privacyBox": {
        height: "380px",
        overflowY: "scroll",
        marginBottom: "32px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
    }
})
// Customizable Area End