import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React, { ChangeEvent, FormEvent } from 'react';
import {
    TextField,
    Radio,
    FormControlLabel
} from "@mui/material";

// type interface
export interface ISurveyListsResponse {
    data: ISurveyLists[];
}
export interface ISurveyLists {
    id: string | number;
    type: string;
    attributes: {
        id: number | string;
        name: string;
        description: string;
        is_activated: boolean;
    };
}

export interface ISurveyData {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        description: string;
        questions: IQuestion[];
    };
}

export interface IQuestion {
    id: number;
    question_type: string;
    question_title: string;
    survey_id: number;
    rating?: number | string;
    options?: IQuestionOption[];
}

export interface IQuestionOption {
    id: number;
    name: string;
}


export interface Answers {
    [key: number]: string | IQuestionOption[] | undefined | object
}

export interface IAnswerLists {
    question_id: number;
    answer: string | number | IQuestionOption | IQuestionOption[] | undefined | object;
}

export interface IValidationErrors {
    [key: number]: string
}

export interface Options {
    id: number
}




// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    surveyLists: ISurveyLists[];
    answers: Answers;
    surveyData: ISurveyData;
    validationErrors: IValidationErrors;
    showSuccessMessage: string;
    initialValues: {
        clientName: string;
        plantWarehouse:string;
        phone:string;
        email:string;
        pointofContact:string;
        date:string;
        branchContact:string;
        branch:string
    }
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SurveysController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdGetSurveyList: string = '';
    getSurveyDetailsApiCallId: string = '';
    surveyQuestionAnswerSubmitApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            surveyLists: [],
            answers: {},
            surveyData: {
                id: "",
                type: "",
                attributes: {
                    id: 65,
                    name: "",
                    description: "",
                    questions: [
                        {
                            id: 53,
                            question_type: "",
                            question_title: "",
                            survey_id: 65
                        }
                    ]
                }
            },
            validationErrors: {},
            showSuccessMessage: "",
            initialValues: {
                clientName: "",
                plantWarehouse:"",
                phone:"",
                email:"",
                pointofContact:"",
                date:"",
                branchContact:"",
                branch:""

            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            const apiRequestCallIds = {
                [this.apiCallIdGetSurveyList]: this.surveyListApiResponse,
                [this.getSurveyDetailsApiCallId]: this.surveyDetailsApiResponse,
                [this.surveyQuestionAnswerSubmitApiCallId]: this.surveyQuestionAnswerSubmitApiResponse,
            };

            if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
                apiRequestCallIds[apiRequestCallId](responseJson, errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const paramId = this.props.navigation.getParam("id");
        if (paramId) {
            this.fatchSurveyDetails(paramId)
        }
        this.getSurveyList();
    }

    // <-------------------------- Here API Calling functionalities {rf} -------------------------->

    getSurveyList = () => {
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': configJSON.applicationJsonContentType,
            token: userToken,
        };

        // rest api call message create
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // save rest api resuest id
        this.apiCallIdGetSurveyList = requestMessage.messageId;

        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.surveyApiEndPoint // end point setup
        );

        // set header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header) // here add header data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod // api method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);


    }

    fatchSurveyDetails = (surveyId: string | number) => {
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': configJSON.applicationJsonContentType,
            token: userToken,
        };

        // rest api call message create
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // save rest api resuest id
        this.getSurveyDetailsApiCallId = requestMessage.messageId;

        // Set EndPoints
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.surveyApiEndPoint}/${surveyId}` // end point setup
        );

        // set header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header) // here add header data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod // api method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }


    submitSurveyQuestionAnswer = (questionAnswerList: IAnswerLists[]) => {
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': configJSON.applicationJsonContentType,
            token: userToken,
        };

        const httpBody = {
            data: [...questionAnswerList]
        };


        // rest api call message create
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // save rest api resuest id
        this.surveyQuestionAnswerSubmitApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.surveyQuestionSubmissionsApiEndPoint // end point setup
        );

        // set header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // sent the body data
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody) // here add httpBody
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod // api method ("POST")
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    // <-------------------------- Here Api responses functionalities {rf} -------------------------->


    surveyListApiResponse = (responseJson: ISurveyListsResponse, errorReponse?: string) => {
        if (responseJson?.data && !errorReponse) {
            this.setState({
                surveyLists: responseJson?.data,
            });
        }
    }

    surveyDetailsApiResponse = (responseJson: { data: ISurveyData }, errorReponse?: string) => {
        this.setState({
            surveyData: responseJson.data
        });

    }

    surveyQuestionAnswerSubmitApiResponse = (responseJson: { data: object, meta: { message: string } }, errorReponse?: string) => {
        if (responseJson.data && !errorReponse) {
            setTimeout(() => {
                this.setState({
                    showSuccessMessage: ""
                });
                this.props.navigation.navigate('Surveys');
            }, 3000);
            this.setState({
                showSuccessMessage: responseJson.meta.message
            });
        }
    }



    // <-------------------------- Here needed helper functionalities {rf} -------------------------->

    backButton = () => {
        this.props?.navigation?.goBack();
    }



    handleAnswerChange = (questionId: number, answer: string | IQuestionOption[] | undefined | IQuestionOption): void => {
        this.setState((prevState) => ({
            answers: {
                ...prevState.answers,
                [questionId]: answer,
            },
        }));
    };

    handleAnswerSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const { answers } = this.state;

        const { questions } = this.state.surveyData.attributes;

        const missingAnswers: number[] = [];

        questions.forEach((question) => {
            if (!answers[question.id]) {
                missingAnswers.push(question.id);
            }
        });

        if (missingAnswers.length > 0) {
            const validationErrors: { [key: number]: string } = {};
            missingAnswers.forEach((questionId) => {
                validationErrors[questionId] = 'This field is required.';
            });

            this.setState({
                validationErrors,
            });
            return;
        }

        const answerList: IAnswerLists[] = Object.entries(answers).map(([questionId, answer]) => ({
            question_id: parseInt(questionId),
            answer,
        }));
        this.submitSurveyQuestionAnswer(answerList);
        this.resetForm();
    };

    resetForm = (): void => {
        this.setState({ answers: {}, validationErrors: {} });
    };

    renderQuestionField = (question: IQuestion): JSX.Element | null => {
        switch (question.question_type) {
            case 'text':
                return (
                    <TextField
                        type="text"
                        value={this.state.answers[question.id] as string | undefined || ''}
                        data-testid='inputField'
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            this.handleAnswerChange(question.id, event.target.value)
                        }
                    />
                );
            case 'radio':
                return (
                    <div>
                        {question.options &&
                            question.options.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    control={
                                        <Radio
                                            checked={
                                                (this.state.answers[question.id] as IQuestionOption[] | undefined || []).some(
                                                    (selectedOption) => selectedOption === option
                                                )
                                            }
                                        />
                                    }
                                    onChange={() => this.handleAnswerChange(question.id, [option])}
                                    data-testid='redioInputField'
                                    label={option.name}
                                />
                            ))}
                    </div>
                );

            case 'checkbox':
                return (
                    <div id="checkBoxId">
                        {question.options &&
                            question.options.map((option, index) => (
                                <label
                                    key={index}
                                    style={{
                                        marginRight: '10px',
                                        fontSize: '1rem',
                                        lineHeight: 1.5,
                                        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                                        letterSpacing: '0.00938em'
                                    }}>
                                    <input
                                        style={{
                                            marginRight: '10px'
                                        }}
                                        type="checkbox"
                                        value="Option 3"
                                        data-testid='checkboxInputField'
                                        checked={
                                            (this.state.answers[question.id] as IQuestionOption[] | undefined || []).some(
                                                (selectedOption) => selectedOption.id === option.id
                                            )
                                        }
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            const isChecked = event.target.checked;

                                            const selectedOptions = this.state.answers[question.id] as
                                                | IQuestionOption[]
                                                | undefined;

                                            let updatedQuestionOptions: IQuestionOption[] | undefined;
                                            if (isChecked) {
                                                updatedQuestionOptions = [
                                                    ...(selectedOptions || []),
                                                    {
                                                        id: option.id,
                                                        name: option.name,
                                                    },
                                                ];
                                            } else {
                                                updatedQuestionOptions = selectedOptions?.filter(
                                                    (selectedOption) => selectedOption.id !== option.id
                                                );
                                            }

                                            this.handleAnswerChange(question.id, updatedQuestionOptions);
                                        }}
                                    />
                                    {option.name}
                                </label>

                            ))}
                    </div>
                );

            case 'rating':
                const ratingArray = [];
                for (let iteration = 1; iteration <= Number(question.rating); iteration++) {
                    ratingArray.push(iteration)
                }

                return (
                    <div>
                        {ratingArray &&
                            ratingArray.map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Radio
                                            checked={this.state.answers[question.id] === `${option}`}
                                        />
                                    }
                                    data-testid='ratingInputField'
                                    onChange={() => this.handleAnswerChange(question.id, `${option}`)}
                                    label={option}
                                />
                            ))}
                    </div>
                );

            default:
                return null;
        }
    };

    // Customizable Area End
}
